import Vue from "vue";
import Vuex from "vuex";
import fun from '../util/cookie.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
	  hasLogin:fun.getCookie('phone')!=null?true:false
  },
  mutations: {
	  login_state(state){
		  console.log(state.hasLogin)
		  state.hasLogin=true
		  
	  }
  },
  actions: {},
  modules: {},
});
