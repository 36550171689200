var fun = {
    setCookie(phone) {
        var exdate = new Date(); // 获取当前登录的时间
        exdate.setTime(exdate.getTime() + 720 * 60 * 1000); // 一天
        // 字符串拼接cookie,因为cookie存储的形式是name=value的形式
        window.document.cookie = "phone" + "=" + phone + ";expires=" + exdate;

    },
    // 第2步，若cookie中有用户名和密码的话，就通过两次切割取出来存到form表单中以供使用，若是没有就没有
    getCookie() {
        if (document.cookie.length > 0) {
            var arr = document.cookie; //因为是数组所以要切割。打印看一下就知道了
        }
        let val;
        if (arr !== undefined) {
            val = arr.split('=').slice(-1)[0]
        } else {
            val = ''

        }

        return val

    },
    // 清除cookie
    clearCookie() {
        this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    }
}
export default fun

