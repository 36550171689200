import Vue from "vue";
import VueRouter from "vue-router";
import fun from "../util/cookie.js";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Manage",
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/manage/manage.vue"),
    },
    {
        path: "/info/:info_id",
        name: "Info",
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/info/info.vue"),
        props: true
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


// router.beforeEach((to, from, next) => {
//     let cookie = fun.getCookie();
//     if (to.name === null) {
//         next({name: 'Index'})
//         return;
//     }
//     if (to.name === 'Info' && cookie !== '') {
//         next()
//         return;
//     }
//     if (to.name === 'Index') {
//         next()
//         return;
//     }
//     if ((to.name === 'Signup' || to.name === 'haveSignup') && cookie === '') {
//         next({name: 'Index'})
//         return;
//     }
//     next()
// });

export default router;
